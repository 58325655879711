class NonTranslatedWords {
    constructor (currentLanguage) {
        if (typeof openDatabase == 'undefined') {
            return this;
        }
        this.dbName = 'nontranslatedwords';
        this.db = openDatabase(this.dbName, '1.0', 'Non Translated Words Collector', 2 * 1024 * 1024);
        this.lang = currentLanguage;
        this.db.transaction(function (tx) {  
           tx.executeSql('CREATE TABLE IF NOT EXISTS Words (key WORD, lang VARCHAR(2))');
        });

        // Check the current non translated if they are translated then remove from database them

        this.db.transaction(function (tx) {
            tx.executeSql('SELECT * FROM Words', [], function (tx, results) {
                var len = results.rows.length, i;
                for (i = 0; i < len; i++){
                    var row = results.rows.item(i);
                    if ( typeof lstrings[row.lang] != 'undefined' && typeof lstrings[row.lang][row.key] != 'undefined' ) {
                        tx.executeSql('DELETE FROM Words WHERE lang=? AND key=?', [row.lang, row.key]);
                    }
                }
            });
        });

    }

    test ( key ) {
        if (typeof openDatabase == 'undefined') {
            return this;
        }
        var lang = this.lang;
        if ( typeof lstrings[lang] != 'undefined' && typeof lstrings[lang][key] == 'undefined' ) {
            this.db.transaction(function (tx) {  
               tx.executeSql('INSERT INTO Words (lang, key) VALUES (?, ?)', [lang, key]);
            });
        }
    }

    fetchAll (cb) {
        if (typeof openDatabase == 'undefined') {
            if ( cb instanceof Function ) {
                cb(ret, JSON.stringify(ret, null, 4));
            } else {
                console.log('WebSQL not implemented this browser, this feature can\'t be used in this browser.');
            }
            return this;
        }
        var lang = this.lang;
        this.db.transaction(function (tx) {
            tx.executeSql('SELECT * FROM Words WHERE lang=?', [lang], function (tx, results) {
                var len = results.rows.length, i;
                var ret = {};
                for (i = 0; i < len; i++){
                    var row = results.rows.item(i);
                    ret[row.key] = row.key;
                }
                if ( cb instanceof Function ) {
                    cb(ret, JSON.stringify(ret, null, 4));
                } else {
                    console.log(JSON.stringify(ret, null, 4)
                        + '\nTotal: ' + len
                        + '\nLanguage:' + lang
                        + (lang=='en' ? '\nNote: This is default language, maybe not necessary the define all keys in translation file.' : '')
                    );
                }
            });
        });
    }
}


var currentLanguageShort = typeof _spPageContextInfo != 'undefined' ? _spPageContextInfo.currentCultureName.split('-')[0] : (function() {
    var lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    if (lang.indexOf('-') !== -1)
        lang = lang.split('-')[0];

    if (lang.indexOf('_') !== -1)
        lang = lang.split('_')[0];
    return lang;
})();

if (!String.prototype.replaceRange)
Object.defineProperty(String.prototype, 'replaceRange', {
    enumerable: false,
    value: function (start, end, substitute) {
        return this.substring(0, start) + substitute + this.substring(end);;
    }
});
if (!String.prototype.format) {
    Object.defineProperty(String.prototype, 'format', {
        enumerable: false,
        value: function() {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function(match, number) { 
                return typeof args[number] != 'undefined'
                    ? args[number]
                    : match
                    ;
            });
        }
    });
}

var nonLocalizedKeys   = {};
//var nonTranslatedWords = new NonTranslatedWords(currentLanguageShort);

Object.defineProperties(String.prototype, {
    translated: {
        enumerable: false,
        get: function () {
            var ret = this[currentLanguageShort];
            return typeof ret == 'string' ? ret : this;
        }
    }
});

for ( var i in lstrings ) {
    Object.defineProperty(String.prototype, i, (function(key) {
        return {
            enumarable: false,
            get: function () {
                var ret = lstrings[key][this];
                if ( typeof ret == 'undefined' && typeof nonLocalizedKeys[this] == 'undefined' ) {
                    nonLocalizedKeys[this] = this;
                    //nonTranslatedWords.test(this);
                }
                return typeof ret != 'undefined' ? ret : this;
            }
        }
    })(i))
}

(function($) {

    function callback () {
        $('[data-translated], .translated, [translated]').each(function (index, item) {
            var qitem = $(item);
            var key = '';

            if ( item.hasAttribute('data-translated') && qitem.data('translated').trim() != '' ) {
                key = qitem.data('translated');
            } else if ( item.hasAttribute('translated') && qitem.attr('translated').trim() != '' ) {
                key = qitem.attr('translated');
            } else if ( qitem.hasClass('translated') ) {
                key = qitem.text().trim();
            }

            qitem.text( key.translated );

            qitem.removeAttr('data-translated');
            qitem.removeClass('translated');
            qitem.removeAttr('translated');
        });
    }
    window.lcb = callback;

    callback(); // Instant call
    $(".main-container").bind("DOMSubtreeModified", callback); // When Dom Modified
    $(document).ready(callback);
    $(window).on('load', callback);


}).apply(window, [jQuery]);